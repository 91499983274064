import styled from "styled-components";
import "../../../static/fonts/fonts.css";

export const LayoutStyled = styled.div`
  margin: 0;
  padding: 0;
  color: white;
  font-family: RadioGrotesk, sans-serif;
  overflow: clip;

  &::after {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    left: -5px;
    bottom: 0;
    height: 100%;
    width: 5px;
    z-index: 3000;    


    box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    -webkit-box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    -moz-box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    
  }
`;

export const RightMargin = styled.div`
  margin: 0;
  padding: 0;
  z-index: 3000;


  &::after {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    right: -5px;
    bottom: 0;
    height: 100%;
    width: 5px;
    z-index: 3000;    
    box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    -webkit-box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    -moz-box-shadow: 0px 11px 15px 3px rgba(242,231,215,0.75);
    
  }
`;

export const TopMargin = styled.div`
position: relative;
z-index: 3000;


&::after {
  content: ' ';
  display: block;
  position: fixed;
  z-index: 3000;
  left: -3px;
  right: -3px;
  top: -10px;
  min-width: 100%;
  height: 10px;    

  box-shadow: 0px 0px 16px 4px rgba(242,231,215,0.75);
  -webkit-box-shadow: 0px 0px 16px 4px rgba(242,231,215,0.75);
  -moz-box-shadow: 0px 0px 16px 4px rgba(242,231,215,0.75);

  
}
`
