import React from "react";
import { LayoutStyled, RightMargin, TopMargin } from "./style";

export const Layout = ({ children}) => {

  React.useEffect(() => {
    document.body.style.background = "black";
  }, [])
  return (
  <LayoutStyled>
    <RightMargin>
      {children}
    </RightMargin>
    <TopMargin/>
  </LayoutStyled>
  )
  
};


